@use 'sass:map';
@use 'variables' as *;

@mixin media($key) {
    @if $key == 'd' {
        @content;
    } @else if map.has-key($breakpoints, $key) {
        @media (min-width: map.get( $breakpoints, $key)) {
            @content;
        }
    } @else {
        @error 'Unfortunately, no value could be retrieved from `#{ $breakpoints}`. '
        + 'Please make sure it is defined in `$breakpoints` map.';
    }
}

@mixin media-max($key) {
    @if $key == 'd' {
        @content;
    } @else if map.has-key($breakpoints, $key) {
        @media (max-width: #{map.get( $breakpoints, $key) - 1px}) {
            @content;
        }
    } @else {
        @error 'Unfortunately, no value could be retrieved from `#{ $breakpoints}`. '
        + 'Please make sure it is defined in `$breakpoints` map.';
    }
}

@function size($key) {
    @return calc(100% / 12 * $key);
}
